import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  ABOUT_PAGE,
  BLOG_PAGE,
  LISTING_POSTS_PAGE,
  AGENT_PROFILE_PAGE,
  PRICING_PLAN_PAGE,
} from 'settings/constant';

const MainMenu = ({ className }) => {
  
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          Home
        </NavLink>
      </Menu.Item>
      
      <Menu.Item key="2">
        <NavLink  to={{ pathname: "./" }} target="_parent" >Blog</NavLink>
      </Menu.Item>

    </Menu>

  );
};

export default MainMenu;
