import React, { useContext } from 'react';
import './footertxt.css';
const Footertext = () => {
	return(
		<p className="footertxtbase">
            <h3>Disclaimer</h3>

All content on this website is intended for general information only, and should not be construed as legal advice,  applicable to your particular situation.  No Consultant-client relationship is created unless and until a binding written engagement letter is signed by both you and your Regulated Consultant. Before taking any action based on this website, you should consider your personal situation and seek professional advice.
Please note that this guide does not advise what documents you must submit, or how to organize your documents for your application AND it is NOT intended for those needing to submit additional/extra documents.  If this is your situation and you follow any of the steps in this guide, then you do so ENTIRELY at your own risk.

Please see the privacy policy at the bottom of our main website <a href="http://hcis.ca/" target="_blank">www.canmigrate.ca</a>
        </p>
	);
}

export default Footertext;