import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import './auth.css';
import { LOGIN_PAGE, REGISTRATION_PAGE } from 'settings/constant';

const AuthMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="1">
        <NavLink to={{ pathname: "mailto: info@webimmigration.ca" }} target="_blank" className="paddingbtn">info@webimmigration.ca</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default AuthMenu;
